import React from 'react';

import arrowIcon from '../../../assets/img/subscribe-arrow-icon.svg';

const Packages = () => (
    <section id="packages" className="packages-section">
        <div className="title-wrap animation">
            <h2>Apescreener <span>Pro</span></h2>
        </div>
        <div className="items-wrap">
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <h5>Starter</h5>
                    <h6>Free</h6>
                    <ul>
                        <li><p>Read 2 ideas/day</p></li>
                        <li><p>Track up to 3 wallets</p></li>
                        <li><p>Up to 3 active chains</p></li>
                        <li><p>All ads</p></li>
                        <li><p>-</p></li>
                        <li><p>Get news only of tokens you hold</p></li>
                        <li><p>Up to 20 tokens in watchlist</p></li>
                        <li><p>Auto trading: 1% fee</p></li>
                    </ul>
                </span>
                <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span>
            </a>
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <h5>Gold</h5>
                    <h6>0.1% or 0.04E/month</h6>
                    <ul>
                        <li><p>Read 5 ideas/day</p></li>
                        <li><p>Track up to 10 wallets</p></li>
                        <li><p>Up to 5 active chains</p></li>
                        <li><p>Targeted and relevant ads</p></li>
                        <li><p>-</p></li>
                        <li><p>Get news from all tokens</p></li>
                        <li><p>Unlimited watchlist</p></li>
                        <li><p>Auto trading 0.5% fee</p></li>
                    </ul>
                </span>
                <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span>
            </a>
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <h5>Diamond</h5>
                    <h6>0.25% or 0.1E/month</h6>
                    <ul>
                        <li><p>Read 20 ideas/day</p></li>
                        <li><p>Track up to 20 wallets</p></li>
                        <li><p>All supported chains</p></li>
                        <li><p>Can opt-out from ads</p></li>
                        <li><p>Alpha community</p></li>
                        <li><p>Get news from all tokens</p></li>
                        <li><p>Unlimited watchlist</p></li>
                        <li><p>Auto trading 0.25% fee</p></li>
                    </ul>
                </span>
                <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span>
            </a>
            <a href="#" className="item item-color animation">
                <span className="text-wrap">
                    <h5>
                        <span className="text">Elite</span>
                        <span className="badge">BEST ROI</span>
                    </h5>
                    <h6>1.25% or 0.5E/month</h6>
                    <ul>
                        <li><p>Read unlimited ideas</p></li>
                        <li><p>Track unlimited wallets</p></li>
                        <li><p>All supported chains</p></li>
                        <li><p>Can opt-out from ads</p></li>
                        <li><p>Alpha community</p></li>
                        <li><p>Get news from all tokens</p></li>
                        <li><p>Unlimited watchlist</p></li>
                        <li><p>No trading fees</p></li>
                    </ul>
                </span>
                <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span>
            </a>
        </div>
    </section>
);

export default Packages;
