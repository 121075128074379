import React, {useEffect} from 'react';

import videoOne from '../../../assets/img/features/features-video-1.webm';
import videoTwo from '../../../assets/img/features/features-video-2.webm';
import videoThree from '../../../assets/img/features/features-video-3.webm';
import videoFour from '../../../assets/img/features/features-video-4.webm';
import aiIcon from '../../../assets/img/features/services-ai-icon.svg';
import insightsIcon from '../../../assets/img/features/services-insights-icon.svg';
import ideasIcon from '../../../assets/img/features/services-ideas-icon.svg';
import tradingIcon from '../../../assets/img/features/services-trading-icon.svg';

const Features = () => {
    const featuresVideos = document.querySelectorAll('.features-section video');
    console.log({featuresVideos});

    const handleScroll = () => {
        Array.from(document.querySelectorAll('.features-section video')).forEach((video) => {
            const {bottom = 0, height = 0} = video.getBoundingClientRect();

            if (bottom - (height / 2) - window.innerHeight < 0) {
                video.play();
            }

            if (video.hasAttribute('controls')) {
                video.removeAttribute('controls');
            }
        })
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return <section id="features" className="features-section">
        <div className="title-wrap animation">
            <h2>What makes us<br/> different?</h2>
        </div>
        <div className="item">
            <div className="text-wrap">
                <div className="icon-wrap animation">
                    <img src={aiIcon} alt="Icon"/>
                </div>
                <h3 className="animation">AI Copilot</h3>
                <h6 className="animation">Our AI Copilot is trained on your personal preferences and trades history.
                    This allow us to give you the best decision for your own circumstances.</h6>
            </div>
            <div className="img-wrap">
                <video src={videoOne} muted="muted" playsInline/>
            </div>
        </div>
        <div className="item item-revert">
            <div className="img-wrap">
                <video src={videoTwo} muted="muted" playsInline/>
            </div>
            <div className="text-wrap">
                <div className="icon-wrap animation">
                    <img src={insightsIcon} alt="Icon"/>
                </div>
                <h3 className="animation">Algorithmic Insights</h3>
                <h6 className="animation">Full analytics of your portfolio helps you understand the performance of
                    your actions, and the exposure you have to different narratives and risk levels.</h6>
            </div>
        </div>
        <div className="item">
            <div className="text-wrap">
                <div className="icon-wrap animation">
                    <img src={ideasIcon} alt="Icon"/>
                </div>
                <h3 className="animation">Degen Ideas</h3>
                <h6 className="animation">Read and rate the ideas of fellow traders. Good ideas get rewarded,
                    incentivizing alpha sharing.</h6>
            </div>
            <div className="img-wrap">
                <video src={videoThree} muted="muted" playsInline/>
            </div>
        </div>
        <div className="item item-revert">
            <div className="img-wrap">
                <video src={videoFour} muted="muted" playsInline/>
            </div>
            <div className="text-wrap">
                <div className="icon-wrap animation">
                    <img src={tradingIcon} alt="Icon"/>
                </div>
                <h3 className="animation">Automatic Trading</h3>
                <h6 className="animation">Some strategies require speed. Our infrastructure based on account
                    abstractions allows you fast and secure transactions.</h6>
            </div>
        </div>
    </section>
};

export default Features;
