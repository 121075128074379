import React from 'react';
import {Link} from 'react-router-dom';

import infoPageBg from '../../assets/img/info-page-bg.png';

const Privacy = () => (
    <main>
        <div className="info-page-section">
            <div className="bg-wrap">
                <img src={infoPageBg} alt="Bg"/>
            </div>
            <div className="headline-wrap">
                <h2>Privacy Policy<br/> for Apescreener</h2>
                <h6>This Privacy Policy (“Policy”) outlines how Apescreener Inc. (“Company”, “we”, “our”, or “us”)
                    gathers, uses, discloses, and protects personal information of users of its website: <Link
                        to="/">https://apescreener.xyz</Link> (the “Site”) and associated services (collectively,
                    “Services”). This Policy applies wherever it is referenced or linked within our Services.</h6>
                <h6>By accessing or using our Services, you agree to the terms of this Policy and our Terms of Use, and
                    consent to our handling of your information as described herein. Please ensure you also review our
                    Terms of Use, which include important limitations on liability and stipulate that any disputes must
                    be resolved individually.</h6>
                <h6>IF YOU DISAGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF USE, PLEASE DO NOT USE OUR
                    SERVICES.</h6>
                <h6>This Policy does not cover information collected through third-party websites or services that you
                    may access through our Services or information submitted to us via email, text message, or other
                    electronic messages, or offline.</h6>
            </div>
            <div className="text-wrap">
                <div className="item-headline">
                    <h4>1. Information We Collect</h4>
                </div>
                <div className="item">
                    <h5>1.1 Personal Information</h5>
                    <p>We aim to collect minimal personal information to enhance user experience. The personal
                        information we may collect includes:</p>
                    <ul>
                        <li>
                            <p>Telegram usernames</p>
                        </li>
                        <li>
                            <p>Cryptocurrency wallet addresses</p>
                        </li>
                        <li>
                            <p>Transaction histories of provided wallets</p>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <h5>1.2 Non-Personal Information</h5>
                    <p>We also collect non-personal information which does not directly identify you, such as:</p>
                    <ul>
                        <li>
                            <p>IP addresses</p>
                        </li>
                        <li>
                            <p>Browser types</p>
                        </li>
                        <li>
                            <p>Operating systems</p>
                        </li>
                        <li>
                            <p>Device types</p>
                        </li>
                        <li>
                            <p>Usage data (e.g., pages visited, time spent on the site)</p>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <h5>1.3 Automatically Collected Information</h5>
                    <p>When you use our Site, certain information is automatically collected, referred to as “Log Data.”
                        This includes:</p>
                    <ul>
                        <li>
                            <p>Internet Protocol (IP) addresses</p>
                        </li>
                        <li>
                            <p>Device and browser types</p>
                        </li>
                        <li>
                            <p>Operating systems</p>
                        </li>
                        <li>
                            <p>Pages or features accessed on our Site and the duration of access</p>
                        </li>
                        <li>
                            <p>Frequency of use</p>
                        </li>
                        <li>
                            <p>Search terms</p>
                        </li>
                        <li>
                            <p>Links clicked on the Site</p>
                        </li>
                        <li>
                            <p>We use this information to manage and improve our Services, and may engage third-party
                                services to analyze this data.</p>
                        </li>
                    </ul>
                </div>
                <div className="item-headline">
                    <h4>2. Use of Personal Information</h4>
                    <p>We use the personal information collected for the following purposes:</p>
                    <ul>
                        <li>
                            <p>To provide and maintain our Services</p>
                        </li>
                        <li>
                            <p>To enable access to our Services</p>
                        </li>
                        <li>
                            <p>To deliver products and services you request</p>
                        </li>
                        <li>
                            <p>To process transactions and send related information</p>
                        </li>
                        <li>
                            <p>To send updates, security alerts, and support messages</p>
                        </li>
                        <li>
                            <p>To optimize user experience and improve our Services</p>
                        </li>
                        <li>
                            <p>To communicate about promotions, events, and news from us and our partners</p>
                        </li>
                    </ul>
                </div>
                <div className="item-headline">
                    <h4>3. Sharing of Personal Information</h4>
                    <p>We do not share your personal information with third parties without your explicit consent,
                        except in the following situations:</p>
                    <ul>
                        <li>
                            <p>Business Transfers: During negotiations or completion of business transactions, such as
                                mergers or acquisitions.</p>
                        </li>
                        <li>
                            <p>Legal Requirements: To comply with applicable laws, regulations, and legal
                                processes.</p>
                        </li>
                    </ul>
                </div>
                <div className="item-headline">
                    <h4>4. Security of Information</h4>
                    <p>We retain personal information as long as necessary to fulfill the purposes outlined in this
                        Policy and comply with legal obligations. We implement industry-standard security measures to
                        protect your information, but cannot guarantee absolute security due to the inherent risks of
                        internet transmissions. Users are responsible for maintaining the security of their account
                        credentials.</p>
                </div>
                <div className="item-headline">
                    <h4>5. User Choices and Rights</h4>
                </div>
                <div className="item">
                    <h5>5.1 Access and Control</h5>
                    <p>You can access and manage your personal information through your account or by contacting us. You
                        may:</p>
                    <ul>
                        <li>
                            <p>Review and update your information</p>
                        </li>
                        <li>
                            <p>Correct inaccuracies</p>
                        </li>
                        <li>
                            <p>Request deletion of your information</p>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <h5>5.2 Cookies and Tracking</h5>
                    <p>You can control the use of cookies through your browser settings. For more information, see our
                        Cookies Policy.</p>
                </div>
                <div className="item-headline">
                    <h4>6. Children’s Privacy</h4>
                    <p>Our Services are not intended for children under the age of 13. We do not knowingly collect
                        personal information from children under 13. If we become aware that we have inadvertently
                        received such information, we will delete it promptly.</p>
                </div>
                <div className="item-headline">
                    <h4>7. Contact Information</h4>
                    <p>If you have any questions or comments about this Policy, please contact us at: <a
                        href="mailto:support@apescreener.xyz">support@apescreener.xyz</a></p>
                </div>
                <div className="item-headline">
                    <h4>8. Changes to This Privacy Policy</h4>
                    <p>We may update this Privacy Policy from time to time. We encourage you to review this page
                        periodically for the latest information on our privacy practices. Any changes will be effective
                        upon posting on our Site, and your continued use of our Services indicates your acceptance of
                        these changes.</p>
                </div>
                <div className="item-headline">
                    <h4>9. Disclaimer</h4>
                    <p>The advice provided by our AI is based on the analysis of your portfolio and trading history.
                        However, it should not be considered financial advice. Users should conduct their own research
                        and consult with a financial advisor before making investment decisions.</p>
                </div>
            </div>
        </div>
    </main>
);

export default Privacy;
