import React from 'react';

const Grid = () => (
    <section className="grid-section">
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
        <div className="item"></div>
    </section>
)

export default Grid;
