import React, { useState, useEffect, useMemo } from 'react';

import Hero from './Hero';
import Why from './Why';
import Strategies from './Strategies';
import Packages from './Packages';
import Features from './Features';
import Ecosystem from './Ecosystem';
import Roadmap from './Roadmap';
import Trade from './Trade';

import Grid from '../Grid';

const Home = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    }

    const handleScroll = () => {
        const strategiesSectionItems = document.querySelector('.strategies-section .items-wrap')
        const {bottom: strategiesBottom = 0, height: strategiesHeight = 0} = strategiesSectionItems?.getBoundingClientRect();

        if (strategiesBottom - (strategiesHeight / 5) - window.innerHeight < 0) {
            strategiesSectionItems.classList.add('animation-active');
        } else {
            strategiesSectionItems.classList.remove('animation-active');
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const isMobile = useMemo(() => screenWidth <= 768, [screenWidth]);

    return (
        <main>
            <Grid/>
            <Hero/>
            <Why/>
            <Strategies isMobile={isMobile}/>
            <Features/>
            <Packages/>
            <Ecosystem isMobile={isMobile}/>
            <Roadmap/>
            <Trade/>
        </main>
    );
}

export default Home;
