import React, {useMemo, useCallback} from 'react';
import {Link, useLocation} from "react-router-dom";

import logo from '../../assets/img/logo.svg';
import coingeckoIcon from '../../assets/img/coingecko-icon.svg';
import xIcon from '../../assets/img/x-icon.svg';
import telegramIcon from '../../assets/img/telegram-icon.svg';
import dextoolsIcon from '../../assets/img/dextools-icon.svg';
import uniswapIcon from '../../assets/img/uniswap-icon.svg';


const Header = (
    {
        pageScrollPosition,
        isHeaderActive,
        setIsHeaderActive
    }
) => {
    let {pathname} = useLocation();

    const isHomePage = useMemo(() => pathname === '/', [pathname]);

    const onNavLinkClick = useCallback((section) => {
        setIsHeaderActive(false);

        if (section) {
            window.scrollTo({
                top: document.getElementById(section).offsetTop - window.innerHeight / 7,
                behavior: 'smooth'
            })
        }
    }, [setIsHeaderActive]);

    return (
        <header
            className={`${isHeaderActive ? 'active-header' : ''} ${pageScrollPosition > 80 ? 'scroll-header' : ''}`}>
            <div className="logo-wrap">
                <Link to="/">
                    <img src={logo} alt="Logo"/>
                </Link>
            </div>
            <div className="toggle-wrap mobile-only">
                <div className="btn-toggle" onClick={() => setIsHeaderActive(!isHeaderActive)}>
                    <span className="text">
                        <p>Menu</p>
                    </span>
                    <span className="icons">
                        <span className="icon"></span>
                        <span className="icon"></span>
                    </span>
                </div>
            </div>
            <div className="nav-wrap">
                <div className="nav-links-wrap">
                    {isHomePage ? (
                        <ul>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('why')}><p>Why Us</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('strategies')}><p>Strategies</p>
                                </Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('features')}><p>Features</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('packages')}><p>Pricing</p></Link>
                            </li>
                            <li>
                                <Link to="/" onClick={() => onNavLinkClick('ecosystem')}><p>Ecosystem</p></Link>
                            </li>
                            <li>
                                <Link to="https://docs.apescreener.xyz/"><p>Whitepaper</p></Link>
                            </li>
                        </ul>
                    ) : (
                        <ul>
                            <li>
                                <Link to="/" onClick={() => setIsHeaderActive(false)}><p>Back To Homepage</p></Link>
                            </li>
                        </ul>
                    )}
                </div>
                <div className="action-wrap">
                    <div className="socials-wrap">
                        <a href="https://www.coingecko.com/en/coins/apescreener" target="_blank" rel="noreferrer">
                            <img src={coingeckoIcon} alt="CoinGecko"/>
                        </a>
                        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xc037f112047b6fd7ff564dae1c5b625a2a2c3bdf"
                           target="_blank" rel="noreferrer">
                            <img src={dextoolsIcon} alt="DEXTools"/>
                        </a>
                        <a href="https://x.com/apescreener" target="_blank" rel="noreferrer">
                            <img src={xIcon} alt="X"/>
                        </a>
                        <a href="https://t.me/ApeScreener" target="_blank" rel="noreferrer">
                            <img src={telegramIcon} alt="Telegram"/>
                        </a>
                        <a href="https://app.uniswap.org/swap?outputCurrency=0x09675e24CA1EB06023451AC8088EcA1040F47585" target="_blank" rel="noreferrer">
                            <img src={uniswapIcon} alt="Uniswap"/>
                        </a>
                    </div>
                    <div className="btn-wrap">
                        <a href="https://dapp.apescreener.xyz">
                            <span className="text">dApp</span>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
