import React from 'react';

import mainImage from '../../../assets/img/ecosystem/ecosystem-main-img.png';
import mainMobileImage from '../../../assets/img/ecosystem/ecosystem-main-img-mobile.png';
import circlesImage from '../../../assets/img/ecosystem/ecosystem-circles-img.png';

const Ecosystem = ({ isMobile }) => (
    <section id="ecosystem" className="ecosystem-section">
        <div className="title-wrap animation">
            <h2>Revenue<br/> Ecosystem</h2>
        </div>
        <div className="content-wrap">
            {isMobile ? (
                <div className="main-img-wrap">
                    <img src={mainMobileImage} alt="Main Image"/>
                </div>
            ) : (
                <>
                    <div className="circles-img-wrap animation">
                        <img src={circlesImage} alt="Circles Image"/>
                    </div>
                    <div className="main-img-wrap animation">
                        <img src={mainImage} alt="Main Image"/>
                    </div>
                </>
            )}
        </div>
    </section>
);

export default Ecosystem;
