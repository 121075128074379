import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => (
    <footer>
        <div className="content-wrap">
            <div className="copyright-wrap">
                <p>Copyright © 2024 ApeScreener</p>
            </div>
            <div className="links-wrap">
                <ul>
                    <li>
                        <Link to="/terms"><p>Terms of Service</p></Link>
                    </li>
                    <li>
                        <Link to="/privacy"><p>Privacy Policy</p></Link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
);

export default Footer;
