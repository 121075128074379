import React from 'react';

import buildSmallIcon from '../../../assets/img/why/why-build-small-icon.svg';
import buildBgIcon from '../../../assets/img/why/why-build-bg-icon.svg';
import maintainSmallIcon from '../../../assets/img/why/why-maintain-small-icon.svg';
import maintainBgIcon from '../../../assets/img/why/why-maintain-bg-icon.svg';
import liquidateSmallIcon from '../../../assets/img/why/why-liquidate-small-icon.svg';
import liquidateBgIcon from '../../../assets/img/why/why-liquidate-bg-icon.svg';

const Why = () => (
    <section id="why" className="why-section">
        <div className="title-wrap animation">
            <h2>Why should you<br/> use ApeScreener?</h2>
            <h6>Empowering the crypto industry<br/> with cutting-edge AI solutions</h6>
        </div>
        <div className="items-wrap">
            <div className="item">
                <div className="bg-wrap">
                    <img src={buildBgIcon} alt="Icon"/>
                </div>
                <div className="icon-wrap animation">
                    <img src={buildSmallIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="animation">Build</h4>
                    <p className="animation">ApeScreener helps you find opportunities and decide how much to invest on
                        it.</p>
                </div>
            </div>
            <div className="item">
                <div className="bg-wrap">
                    <img src={maintainBgIcon} alt="Icon"/>
                </div>
                <div className="icon-wrap animation">
                    <img src={maintainSmallIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="animation">Maintain</h4>
                    <p className="animation">Should you buy more, or derisk a little bit? These day to day decisions are
                        not overwhelming anymore.</p>
                </div>
            </div>
            <div className="item">
                <div className="bg-wrap">
                    <img src={liquidateBgIcon} alt="Icon"/>
                </div>
                <div className="icon-wrap animation">
                    <img src={liquidateSmallIcon} alt="Icon"/>
                </div>
                <div className="text-wrap">
                    <h4 className="animation">Liquidate</h4>
                    <p className="animation">It has happened to everyone. Unrealized gains become realized loses. Learn
                        when to take profits!</p>
                </div>
            </div>
        </div>
    </section>
);

export default Why;
