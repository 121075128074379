import React from 'react';

import infoPageBg from '../../assets/img/info-page-bg.png';

const Terms = () => (
    <main>
        <div className="info-page-section">
            <div className="bg-wrap">
                <img src={infoPageBg} alt="Bg"/>
            </div>
            <div className="headline-wrap">
                <h2>Terms of Service<br/> for Apescreener</h2>
                <h6>Welcome to Apescreener! By accessing or using our website, https://apescreener.xyz/, and related
                    services (collectively, the "Service"), you agree to comply with and be bound by the following Terms
                    of Service ("Terms"). Please read them carefully. If you do not agree to these Terms, do not use the
                    Service.</h6>
            </div>
            <div className="text-wrap">
                <div className="item">
                    <h5>1. Acceptance of Terms</h5>
                    <p>Welcome to Apescreener! By accessing or using our website, https://apescreener.xyz/, and related
                        services (collectively, the "Service"), you agree to comply with and be bound by the following
                        Terms of Service ("Terms"). Please read them carefully. If you do not agree to these Terms, do
                        not use the Service.</p>
                </div>
                <div className="item">
                    <h5>2. Eligibility</h5>
                    <p>You must be at least 18 years old and legally capable of entering into contracts to use our
                        Service. By using Apescreener, you represent and warrant that you meet these requirements.</p>
                </div>
                <div className="item">
                    <h5>3. Description of Service</h5>
                    <p>Apescreener provides an AI-driven portfolio advisor and tracker for cryptocurrencies. Our Service
                        aims to help users manage, grow, and monitor their cryptocurrency investments.</p>
                </div>
                <div className="item">
                    <h5>4. User Responsibilities</h5>
                    <p>You are responsible for maintaining the confidentiality of your account information and for all
                        activities that occur under your account. You agree to notify us immediately of any unauthorized
                        use of your account or any other breach of security.</p>
                </div>
                <div className="item">
                    <h5>5. Prohibited Uses</h5>
                    <p>You agree not to:</p>
                    <ul>
                        <li>
                            <p>Use the Service for any illegal activities.</p>
                        </li>
                        <li>
                            <p>Interfere with the security or integrity of the Service.</p>
                        </li>
                        <li>
                            <p>Attempt to gain unauthorized access to any part of the Service.</p>
                        </li>
                        <li>
                            <p>Transmit any harmful or malicious software.</p>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <h5>6. Intellectual Property</h5>
                    <p>All content, trademarks, logos, and other intellectual property related to Apescreener are the
                        property of Apescreener Inc. You may not use, reproduce, distribute, or create derivative works
                        from any content provided through the Service without our explicit permission.</p>
                </div>
                <div className="item">
                    <h5>7. Privacy</h5>
                    <p>We value your privacy and strive to protect your personal information. Our Privacy Policy
                        outlines how we collect, use, and safeguard your data. By using the Service, you agree to the
                        terms of our Privacy Policy.</p>
                </div>
                <div className="item">
                    <h5>8. Third-Party Links</h5>
                    <p>Our Service may contain links to third-party websites. We do not control, endorse, or assume
                        responsibility for any third-party content. Accessing third-party sites is at your own risk.</p>
                </div>
                <div className="item">
                    <h5>9. Disclaimer of Warranties</h5>
                    <p>The Service is provided "as is" and "as available" without any warranties of any kind, either
                        express or implied. We do not guarantee that the Service will be uninterrupted, error-free, or
                        free of viruses or other harmful components.</p>
                </div>
                <div className="item">
                    <h5>10. Limitation of Liability</h5>
                    <p>In no event shall Apescreener Inc., its officers, directors, employees, or agents be liable for
                        any indirect, incidental, special, consequential, or punitive damages, including loss of
                        profits, data, use, goodwill, or other intangible losses, resulting from:</p>
                    <ul>
                        <li>
                            <p>Your use or inability to use the Service.</p>
                        </li>
                        <li>
                            <p>Any unauthorized access to or use of our servers and/or any personal information
                                stored therein.</p>
                        </li>
                        <li>
                            <p>Any bugs, viruses, trojan horses, or similar issues transmitted through the Service by
                                any third party.</p>
                        </li>
                        <li>
                            <p>Any errors or omissions in any content or for any loss or damage incurred as a result
                                of your use of any content posted, emailed, transmitted, or otherwise made available
                                through the Service.</p>
                        </li>
                    </ul>
                </div>
                <div className="item">
                    <h5>11. Indemnification</h5>
                    <p>You agree to defend, indemnify, and hold harmless Apescreener Inc. and its affiliates from any
                        claims, damages, liabilities, losses, costs, and expenses arising from your use of the Service
                        or violation of these Terms.</p>
                </div>
                <div className="item">
                    <h5>12. Governing Law</h5>
                    <p>These Terms are governed by the laws of the State of Delaware, without regard to its conflict of
                        law principles. Any legal action or proceeding related to your access to or use of the Service
                        shall be instituted in a state or federal court in Delaware.</p>
                </div>
                <div className="item">
                    <h5>13. Dispute Resolution</h5>
                    <p>Any disputes arising out of or in connection with these Terms or the Service shall be resolved
                        through binding arbitration in accordance with the rules of the American Arbitration
                        Association. The arbitration will take place in Delaware, and the arbitrator's decision will be
                        final and binding.</p>
                </div>
                <div className="item">
                    <h5>14. Termination</h5>
                    <p>We reserve the right to terminate or suspend your access to the Service at our sole discretion,
                        without prior notice or liability, for any reason, including but not limited to a breach of
                        these Terms.</p>
                </div>
                <div className="item">
                    <h5>15. Contact Information</h5>
                    <p>For any questions about these Terms, please contact us at <a
                        href="mailto:support@apescreener.xyz">support@apescreener.xyz</a></p>
                </div>
            </div>
        </div>
    </main>
);

export default Terms;
